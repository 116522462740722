var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","margin-top":"20px"}},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{key:_vm.selectedStakeholder ? _vm.selectedStakeholder.key : 'null',staticStyle:{"border-top-right-radius":"0px","border-bottom-right-radius":"0px"},attrs:{"outlined":"","auto-select-first":"","clearable":"","label":"Stakeholders","items":_vm.stakeholders,"item-text":"friendlyName","return-object":"","color":"primary"},model:{value:(_vm.selectedStakeholder),callback:function ($$v) {_vm.selectedStakeholder=$$v},expression:"selectedStakeholder"}}),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.state.Permissions.StakeholderCreate)?_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"20px","height":"56px","border-top-left-radius":"0px","border-bottom-left-radius":"0px"},attrs:{"color":"primary","block":""},on:{"click":_vm.addStakeholderToCampaign}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Add stakeholder")])])],1)],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{key:_vm.selectedTeam ? _vm.selectedTeam.key : 'null',staticStyle:{"border-top-right-radius":"0px","border-bottom-right-radius":"0px"},attrs:{"outlined":"","auto-select-first":"","clearable":"","label":"Teams","items":_vm.teams,"item-text":"name","return-object":"","color":"primary"},model:{value:(_vm.selectedTeam),callback:function ($$v) {_vm.selectedTeam=$$v},expression:"selectedTeam"}}),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.state.Permissions.TeamCreate)?_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"20px","height":"56px","border-top-left-radius":"0px","border-bottom-left-radius":"0px"},attrs:{"color":"primary","block":""},on:{"click":_vm.addTeamToCampaign}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Add stakeholder/team")])])],1)],1)])],1)],1),(_vm.campaignTeamsFromApi.length > 0)?_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"-22px"},attrs:{"cols":"12"}},[_c('v-chip-group',{staticClass:"d-flex flex-wrap",attrs:{"column":""}},_vm._l((_vm.campaignTeamsFromApi),function(team,index){return _c('v-chip',{key:index,staticClass:"text-capitalize",staticStyle:{"border-radius":"5px","height":"45px","background-color":"transparent","cursor":"pointer"},style:('border: 1px solid ' + _vm.stringToColor(team.name) + ' !important;'),on:{"click":function($event){return _vm.showTeamUsers(team)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"medium"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(team.name.toUpperCase())+" "),_c('v-icon',{attrs:{"small":"","fab":""},on:{"click":function($event){$event.stopPropagation();_vm.deleteTeamFromCampaignDialog = true;
            _vm.selectedTeamForDelete = team;}}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"medium"}},[_vm._v(_vm._s(team.name)+" (Team)")])]),_c('div',{staticStyle:{"width":"100%","height":"5px","position":"absolute","bottom":"0","left":"0"},style:('background-color:' + _vm.stringToColor(team.name))})],1)}),1)],1)],1):_vm._e(),(_vm.campaignStakeholdersFromApi.length > 0)?_c('div',{staticStyle:{"background-color":"rgb(0 0 0 / 47%)","border-radius":"5px","overflow":"hidden","margin-top":"5px"}},[(_vm.campaignStakeholdersFromApi.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"primary","grow":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.campaignStakeholdersFromApi),function(stakeholder){return _c('v-tab',{key:stakeholder.id,on:{"click":function($event){_vm.selectedAddedStakeholder = [stakeholder]}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-office-building")]),_vm._v(" "+_vm._s(stakeholder.stakeholder.friendlyName)+" "),(_vm.loggedInUserStakeholderId == stakeholder.stakeholder.id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-crown ")])]}}],null,true)},[_c('span',[_vm._v("Your parent Stakeholder")])]):_vm._e()],1)}),1)],1)],1):_vm._e(),(_vm.selectedAddedStakeholder && _vm.selectedAddedStakeholder.length > 0)?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","clearable":"","label":"Search Users","single-line":"","outlined":"","hide-details":"","disabled":_vm.usersAssociatedWithCampaign.length > 0},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticStyle:{"height":"56px","margin-left":"10px"},attrs:{"color":"red"},on:{"click":function($event){_vm.deleteStakeholderFromCampaignDialog = true;
              _vm.selectedStakeholderForDelete = _vm.selectedAddedStakeholder[0];}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Remove Stakeholder ")],1)],1),(_vm.usersAssociatedWithCampaign.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.added",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"ma-2"},[_c('v-checkbox',{on:{"change":function($event){return _vm.toggleUserInCampaign(item)}},model:{value:(item.addedToCampaign),callback:function ($$v) {_vm.$set(item, "addedToCampaign", $$v)},expression:"item.addedToCampaign"}})],1)]}},{key:"item.image",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"ma-2"},[_c('v-avatar',{attrs:{"size":"60"}},[_c('img',{attrs:{"src":item.contactCard.profilePicture
                        ? item.contactCard.profilePicture.urlThumb
                        : 'contact-placeholder.jpg'}})])],1)]}},(_vm.burstId !== null)?{key:"item.isOwner",fn:function(ref){
                        var item = ref.item;
return [_c('div',{},[_c('v-checkbox',{attrs:{"on-icon":'mdi-crown',"off-icon":'mdi-crown-outline'},on:{"change":function($event){return _vm.checkIfAdmin(item)}},model:{value:(item.isOwner),callback:function ($$v) {_vm.$set(item, "isOwner", $$v)},expression:"item.isOwner"}})],1)]}}:null],null,true)}):_c('v-col',{staticClass:"text-center"},[_c('span',{staticStyle:{"text-align":"center"}},[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" No users have been associated with this stakeholder.")],1)])],1)],1):_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v("Select a stakeholder to view users.")])])],1)],1):_c('v-row',{staticStyle:{"padding-bottom":"20px"}},[_c('v-col',{staticClass:"text-center mt-4"},[_c('span',[_vm._v("No stakeholders have been added to this campaign.")])])],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.teamUsersDialog),callback:function ($$v) {_vm.teamUsersDialog=$$v},expression:"teamUsersDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"white--text primary"},[_vm._v(" "+_vm._s(_vm.selectedTeamForUsers ? _vm.selectedTeamForUsers.name : '')+" Team Members ")]),_c('v-card-text',[_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","clearable":"","label":"Search Team Members","single-line":"","outlined":"","hide-details":""},model:{value:(_vm.teamSearch),callback:function ($$v) {_vm.teamSearch=$$v},expression:"teamSearch"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredTeamUsers,"search":_vm.teamSearch},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"ma-2"},[_c('v-avatar',{attrs:{"size":"60"}},[_c('img',{attrs:{"src":item.contactCard.profilePicture
                    ? item.contactCard.profilePicture.urlThumb
                    : 'contact-placeholder.jpg'}})])],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.closeTeamDialog}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.promptCreateUserDialog),callback:function ($$v) {_vm.promptCreateUserDialog=$$v},expression:"promptCreateUserDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('span',[_vm._v("This contact has no user account, would you like to create a user account for this contact and then add to campaign?")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Select Role For New User*","items":_vm.roles,"item-text":"name","item-value":'id'},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selectedRole === null},on:{"click":_vm.createUserAcc}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"white"},on:{"click":_vm.unselectUser}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.deleteStakeholderFromCampaignDialog),callback:function ($$v) {_vm.deleteStakeholderFromCampaignDialog=$$v},expression:"deleteStakeholderFromCampaignDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('span',[_vm._v("Are you sure you want to remove this stakeholder from this campaign?"),_c('br'),_vm._v(" Note: Stakeholder users will still remain part of the campaign")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteStakeholder}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"white"},on:{"click":function($event){_vm.deleteStakeholderFromCampaignDialog = false;
              _vm.selectedStakeholderForDelete = null;}}},[_vm._v(" Cancel ")])],1)],1)],1),(_vm.deleteTeamFromCampaignDialog)?_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.deleteTeamFromCampaignDialog),callback:function ($$v) {_vm.deleteTeamFromCampaignDialog=$$v},expression:"deleteTeamFromCampaignDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('span',[_vm._v("Are you sure you want to remove the "),_c('b',[_vm._v("("+_vm._s(_vm.selectedTeamForDelete.name)+")")]),_vm._v(" team from this campaign? "),_c('br'),_vm._v(" Note: Stakeholder users will still remain part of the campaign")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteTeamFromCampaign}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"white"},on:{"click":function($event){_vm.deleteTeamFromCampaignDialog = false;
              _vm.selectedTeamForDelete = null;}}},[_vm._v(" Cancel ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }