<template>
  <v-card-text>
    <div class="d-flex" style="width: 100%;margin-top: 20px">
      <v-row dense style="width: 100%">
      <v-col cols="6">
        <div class="d-flex">
          <v-autocomplete
          v-model="selectedStakeholder"
          style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;"
          :key="selectedStakeholder ? selectedStakeholder.key : 'null'"
          outlined
          auto-select-first
          clearable
          label="Stakeholders"
          :items="stakeholders"
          item-text="friendlyName"
          return-object
          color="primary"
        />
        <div>
          <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              v-if='$store.state.Permissions.StakeholderCreate'
              style="width: 20px;height: 56px;border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
              v-bind="attrs"
              v-on="on"
              color="primary"
              block
              @click="addStakeholderToCampaign"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add stakeholder</span>
        </v-tooltip>

        </div>
        </div>
      </v-col>

      <v-col cols="6">
      <div class="d-flex">
        <v-autocomplete
          v-model="selectedTeam"
          style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;"
          :key="selectedTeam ? selectedTeam.key : 'null'"
          outlined
          auto-select-first
          clearable
          label="Teams"
          :items="teams"
          item-text="name"
          return-object
          color="primary"
        />
      <div>
      <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              v-if='$store.state.Permissions.TeamCreate'
              style="width: 20px;height: 56px;border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
              v-bind="attrs"
              v-on="on"
              color="primary"
              block
              @click="addTeamToCampaign"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add stakeholder/team</span>
        </v-tooltip>
    </div>
      </div>
      </v-col>
    </v-row>
    </div>

    <!-- Team chips -->
    <v-row v-if="campaignTeamsFromApi.length > 0">
      <v-col cols="12" style="margin-top: -22px;">
        <v-chip-group
          column
          class="d-flex flex-wrap"
        >
        <v-chip
  v-for="(team, index) in campaignTeamsFromApi" 
  :key="index"
  class="text-capitalize"
  style="border-radius: 5px; height: 45px; background-color: transparent; cursor: pointer;"
  :style="'border: 1px solid ' + stringToColor(team.name) + ' !important;'"
  @click="showTeamUsers(team)"
>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        style="font-size: medium"
      >
        {{ team.name.toUpperCase() }}
        <v-icon
          small
          fab
          @click.stop="
            deleteTeamFromCampaignDialog = true;
            selectedTeamForDelete = team;
          "
        >
          mdi-close
        </v-icon>
      </span>
    </template>
    <span style="font-size: medium">{{ team.name }} (Team)</span>
  </v-tooltip>
  <div style="width: 100%; height: 5px; position: absolute; bottom: 0; left: 0;" :style="'background-color:' + stringToColor(team.name)"></div>
</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- USER VIEW -->
    <div v-if="campaignStakeholdersFromApi.length > 0" style="background-color: rgb(0 0 0 / 47%);border-radius: 5px;overflow: hidden;margin-top:5px">
      <v-row v-if="campaignStakeholdersFromApi.length > 0">
        <v-col cols="12">
          <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            color="primary"
            grow
          >
            <v-tab
              v-for="stakeholder in campaignStakeholdersFromApi"
              :key="stakeholder.id"
              @click="selectedAddedStakeholder = [stakeholder]"
            >
              <v-icon left>mdi-office-building</v-icon>
              {{ stakeholder.stakeholder.friendlyName }}
              <v-tooltip top v-if="loggedInUserStakeholderId == stakeholder.stakeholder.id">
              <template v-slot:activator="{on, attrs}">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-crown
                </v-icon>
              </template>
              <span>Your parent Stakeholder</span>
            </v-tooltip>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row
        v-if="selectedAddedStakeholder && selectedAddedStakeholder.length > 0"
        class="pa-0 ma-0"
      >
        <v-col
          cols="12"
        >
        <div class="d-flex">
          <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          label="Search Users"
          single-line
          outlined
          hide-details
          class="mb-4"
          :disabled="usersAssociatedWithCampaign.length > 0"
        />
        <v-btn color="red" style="    height: 56px;
    margin-left: 10px;" @click="
              deleteStakeholderFromCampaignDialog = true;
              selectedStakeholderForDelete = selectedAddedStakeholder[0];
            ">
          <v-icon>mdi-delete</v-icon>
          Remove Stakeholder
        </v-btn>
        </div>
        <!-- Data Table of users of selected stakeholder-->
          <v-data-table
            v-if="usersAssociatedWithCampaign.length > 0"
            :headers="headers"
            :items="filteredUsers"
            :search="search"
          >
            <template v-slot:item.added="{ item }">
              <div class="ma-2">
                <v-checkbox
                  v-model="item.addedToCampaign"
                  @change="toggleUserInCampaign(item)"
                />
              </div>
            </template>
            <template v-slot:item.image="{ item }">
              <div class="ma-2">
                <v-avatar size="60">
                  <img
                    :src="
                      item.contactCard.profilePicture
                        ? item.contactCard.profilePicture.urlThumb
                        : 'contact-placeholder.jpg'
                    "
                  >
                </v-avatar>
              </div>
            </template>
            <template v-if='burstId !== null' v-slot:item.isOwner="{ item }">
              <div class="">
                <v-checkbox
                  :on-icon="'mdi-crown'"
                  :off-icon="'mdi-crown-outline'"
                  v-model="item.isOwner"
                  @change="
                    checkIfAdmin(item)
                  "
                />
              </div>
            </template>
          </v-data-table>
          <v-col
          v-else
          class="text-center"
        >
          <span
            style="text-align: center"
          ><v-icon>mdi-alert</v-icon> No users have been associated with this stakeholder.</span>
        </v-col>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <span
            style="text-align: center"
          >Select a stakeholder to view users.</span>
        </v-col>
      </v-row>
    </div>

    <v-row v-else style="padding-bottom: 20px">
      <v-col class="text-center mt-4">
        <span>No stakeholders have been added to this campaign.</span>
      </v-col>
    </v-row>

    <v-dialog
  v-model="teamUsersDialog"
  width="800"
>
  <v-card>
    <v-card-title class="white--text primary">
      {{ selectedTeamForUsers ? selectedTeamForUsers.name : '' }} Team Members
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="teamSearch"
        append-icon="mdi-magnify"
        clearable
        label="Search Team Members"
        single-line
        outlined
        hide-details
        class="mb-4"
      />
      <v-data-table
        :headers="headers"
        :items="filteredTeamUsers"
        :search="teamSearch"
      >
        <template v-slot:item.image="{ item }">
          <div class="ma-2">
            <v-avatar size="60">
              <img
                :src="
                  item.contactCard.profilePicture
                    ? item.contactCard.profilePicture.urlThumb
                    : 'contact-placeholder.jpg'
                "
              >
            </v-avatar>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="closeTeamDialog"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    <v-dialog
      v-model="promptCreateUserDialog"
      width="600"
    >
      <v-card class="pa-4">
        <span>This contact has no user account, would you like to create a user
          account for this contact and then add to campaign?</span>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedRole"
              label="Select Role For New User*"
              :items="roles"
              item-text="name"
              :item-value="'id'"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="selectedRole === null"
            @click="createUserAcc"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="unselectUser"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteStakeholderFromCampaignDialog"
      width="600"
    >
      <v-card class="pa-4">
        <span>Are you sure you want to remove this stakeholder from this campaign?<br/>
        Note: Stakeholder users will still remain part of the campaign</span>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteStakeholder"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="
              deleteStakeholderFromCampaignDialog = false;
              selectedStakeholderForDelete = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteTeamFromCampaignDialog"
      width="600"
      v-if="deleteTeamFromCampaignDialog"
    >
      <v-card class="pa-4">
        <span>Are you sure you want to remove the <b>({{ selectedTeamForDelete.name }})</b> team from this campaign?
          <br/>
          Note: Stakeholder users will still remain part of the campaign</span>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteTeamFromCampaign"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="
              deleteTeamFromCampaignDialog = false;
              selectedTeamForDelete = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
  import StakeholderController from '@/services/controllers/Stakeholder'
  import UserController from '@/services/controllers/User'
  import CampaignController from '@/services/controllers/Campaign'
  import countryPhonePrefixes from '../../../public/country-phone-prefix.json'

  import uniqBy from 'lodash/uniqBy';
  
  export default {
    props: {
      campaignId: {
        required: true,
      },
      burstId:{
        type: Number,
        default: null,
      },
      ownerId: {
        type: Number,
        default: null,
      },
      type:{
        type: String,
        default: null,
      },
    },

    data: () => ({
      teamSearch: '',
      teamUsersDialog: false,
      selectedTeamUsers: [],
      selectedTeamForUsers: null,
      selectedTab: 0,
      search: '',
      selectedStakeholder: null,
      requiredRule: [(v) => !!v || 'Field is required'],
      stakeholders: [],
      teams: [],
      addedStakeholders: [],
      validContactCard: false,
      stakeholderSearchString: null,
      selectedAddedStakeholder: null,
      // User ids
      loggedInUserStakeholderId: null,
      loggedInUserId: null,
      selectedStakeholderForDelete: null,
      selectedRole: null,
      deleteStakeholderFromCampaignDialog: false,
      deleteTeamFromCampaignDialog: false,
      selectedTeamForDelete: null,
      promptCreateUserDialog: false,
      editContactCardDialog: false,
      createdContactCardId: null,
      countryCodeOptions: countryPhonePrefixes,
      uploadedContactCardLogo: null,
      addContactDialog: false,
      selectedUserForEdit: null,
      // Stakeholders
      usersAssociatedWithCampaign: [],
      campaignStakeholdersFromApi: [],
      campaignTeamsFromApi: [],
      selectedAddedTeam: null,
      selectedTeam: null,
      originalCampaignStakeholders: [],
      imgUrl: null,
      mediaUploadId: null,
      selectedContact: null,
      roles: [],
      newContactCard: {
        firstName: null,
        surname: null,
        countryCode: null,
        phoneNumber: null,
        email: null,
        jobTitle: null,
      }, 
      headers: [
        {
          sortable: false,
          value: 'added',
        },
        {
          sortable: false,
          value: 'image',
        },
        {
          sortable: true,
          text: 'Forename',
          value: 'contactCard.firstName',
        },
        {
          sortable: true,
          text: 'Surname',
          value: 'contactCard.surname',
        },
        {
          sortable: true,
          text: 'Title',
          value: 'contactCard.jobTitle',
        },
        {
          sortable: false,
          text: 'Email',
          value: 'contactCard.email',
        },
      ],
    }),

    watch: {
      async selectedTab(newVal) {
        if (this.campaignStakeholdersFromApi[newVal]) {
          const stakeholder = this.campaignStakeholdersFromApi[newVal];
          this.selectedAddedStakeholder = [stakeholder];
          await UserController.searchContactCardsById(stakeholder.stakeholder.id)
            .then(async (contactsInStakeholders) => {
              await this.processContactsInStakeholder(contactsInStakeholders);
            });
        }
      },
      async selectedAddedStakeholder (value, oldValue) {
        console.log("value",value)
        if(value !== null && value.length != 0) {
          // Get users for stakeholder id
          await UserController.searchContactCardsById(value[0].stakeholder.id).then(async (contactsInStakeholders) => { await this.processContactsInStakeholder(contactsInStakeholders) })
        }
      },
      // When selected stakeholder is changed, check if it is already added to the campaign, if not, get all contacts in stakeholder and check if they are added to the campaign
      selectedStakeholder (value, oldValue) {
        return
        if (value) {
          
          // Get users for stakeholder id
          UserController.searchContactCardsById(value.id)
          .then((contactsInStakeholders) => {
            CampaignController.getAllUsers(this.campaignId)
            .then((res) => {
              for (var i = 0; i < contactsInStakeholders.data.length; i++) {
                // match id of contact card with Id of users added to campaign
                for (var j = 0; j < res.data.length; j++) {
                  if (contactsInStakeholders.data[i].user) {
                    if (
                      contactsInStakeholders.data[i].user.id === res.data[j].id
                    ) {
                      contactsInStakeholders.data[i].addedToCampaign = true
                    }
                  }
                }
              }
              this.usersAssociatedWithCampaign = contactsInStakeholders.data
            })
          })
        }
      },
    },
    mounted(){
      if(this.burstId !== null) {
        this.headers.push({
          sortable: false,
          text: 'Burst Owner',
          value: 'isOwner',
        })
      }
        
    },
    async created () {
      this.getRoles()
      await this.getUser()
      this.getAllStakeholders()
      this.getAllTeams ()
      await this.getStakeholdersInCampaign()
      await this.getTeamsInCampaign();

    },
    computed: {
      filteredTeamUsers() {
    if (!this.teamSearch) {
      return this.selectedTeamUsers;
    }
    
    const searchTerm = this.teamSearch.toLowerCase();
    return this.selectedTeamUsers.filter(user => {
      const contactCard = user.contactCard;
      return (
        (contactCard.firstName && contactCard.firstName.toLowerCase().includes(searchTerm)) ||
        (contactCard.surname && contactCard.surname.toLowerCase().includes(searchTerm)) ||
        (contactCard.jobTitle && contactCard.jobTitle.toLowerCase().includes(searchTerm)) ||
        (contactCard.email && contactCard.email.toLowerCase().includes(searchTerm))
      );
    });
  },
  filteredUsers() {
    if (!this.search) {
      return this.sortedUsersAssociatedWithCampaign;
    }
    
    const searchTerm = this.search.toLowerCase();
    return this.sortedUsersAssociatedWithCampaign.filter(user => {
      const contactCard = user.contactCard;
      return (
        (contactCard.firstName && contactCard.firstName.toLowerCase().includes(searchTerm)) ||
        (contactCard.surname && contactCard.surname.toLowerCase().includes(searchTerm)) ||
        (contactCard.jobTitle && contactCard.jobTitle.toLowerCase().includes(searchTerm)) ||
        (contactCard.email && contactCard.email.toLowerCase().includes(searchTerm))
      );
    });
  },
      sortedUsersAssociatedWithCampaign() {
        // Create a deep clone of the array to avoid reference issues
        const sortedUsers = JSON.parse(JSON.stringify(this.usersAssociatedWithCampaign));
        
        // Sort the array: true values first, then false values
        return sortedUsers.sort((a, b) => {
          if (a.addedToCampaign === b.addedToCampaign) return 0;
          return a.addedToCampaign ? -1 : 1;
        });
      }
    },
    methods: {
      closeTeamDialog() {
    this.teamUsersDialog = false;
    this.teamSearch = ''; // Reset the search when closing
    this.selectedTeamUsers = [];
    this.selectedTeamForUsers = null;
  },

  async showTeamUsers(team) {
    this.selectedTeamForUsers = team;
    const users = await this.getAllTeamUsers(team.id);
    this.selectedTeamUsers = users;
    this.teamSearch = ''; // Reset the search when opening
    this.teamUsersDialog = true;
  },
      stringToColor(str) {
          // Create a hash from the string
          let hash = 0;
          for (let i = 0; i < str.length; i++) {
              hash = str.charCodeAt(i) + ((hash << 5) - hash); // Hashing algorithm
          }
          
          // Create a color from the hash
          const r = (hash & 0xFF0000) >> 16; // Red
          const g = (hash & 0x00FF00) >> 8;  // Green
          const b = hash & 0x0000FF;         // Blue
          
          // Convert to a CSS-compatible color
          return `rgb(${r}, ${g}, ${b})`;
      },

      // function that sets the selected user isOwner to true using $set and the rest to false
      async checkIfAdmin(item){
        let arr = [...this.usersAssociatedWithCampaign]
        arr.forEach(contact => {
          if (contact.contactCard.id === item.contactCard.id) {
            contact.isOwner = true
          } else {
            contact.isOwner = false
          }
        })
        this.usersAssociatedWithCampaign = arr
        if(item.isOwner) {
          await CampaignController.setBurstOwner({
            id: this.burstId,
            ownerId: item.user.id
          }).then((res) => {
            // snackbacr succes
            this.$root.$emit('snackbarSuccess', 'Burst owner changed successfully')
            if(this.type === 'manageBurst'){
              this.$root.$emit('updateBurstOwnerManageBursts', res.data, this.burstId)
            } else {
              this.$root.$emit('updateBurstOwner', res.data, this.burstId)
            }
          }).catch((err) => {
            // snackbacr error
            this.$root.$emit('snackbarError', err)
            console.log(err)
          })
        } else {
          // snackbacr error
          this.$root.$emit('snackbarWarning', 'This user is already the owner of this burst')
        }
        this.$nextTick()
      },
      async getUser() {
        const res = await UserController.whoAmI().catch(err => {
          // Catch and display snackbar error message 
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        if(res && res.data && res.data.contactCard) {
          // Set logged in user id and stakeholder id
          this.loggedInUserStakeholderId = res.data.contactCard.stakeholder.id
          this.loggedInUserId = res.data.contactCard.id
        }
      },

      unselectUser () {
        this.promptCreateUserDialog = false
        this.createdContactCardId = null
        this.selectedContact.addedToCampaign = null
      },

      async toggleUserInCampaign (item) {
        // Check if selected stakeholder is added to the campaign
        //console.log(this.selectedAddedStakeholder)
        //console.log(this.originalCampaignStakeholders)
        //console.log(item)
        // Check if selectedAddedStakeholder is present in this.originalCampaignStakeholders
        var stakeholderExists = this.originalCampaignStakeholders.find(stakehold => parseInt(stakehold.stakeholder.id) === parseInt(item.contactCard.stakeholder.id))
        //console.log(stakeholderExists)
        
        // If index is -1, it means that the selected stakeholder is not added to the campaign        
        if (item.addedToCampaign === true && stakeholderExists !== undefined) {
          // Add user to campaign.
          if (item.user) {
            await this.addUserToCampaign(item.user.id,undefined,false)
          } else {
            this.selectedContact = item
            this.promptCreateUserDialog = true
            // If user object is null do popup that says would you like to create user to add to campaign and proceed from there.
            this.createdContactCardId = item.contactCard.id
          }
        } else if (stakeholderExists === undefined && item.addedToCampaign === true) {
          // If stakeholder not added to the campaign, show snackbar error message add stakeholder to campaign before adding users
          this.$root.$emit('snackbarSuccess','Successfully added stakeholder')
          CampaignController.setStakeholders({
            campaignId: this.campaignId,
            campaignStakeholders: this.formatStakeholdersArray(),
          })
          .then(async (res) => {
            if (item.user) {
              await this.addUserToCampaign(item.user.id,undefined,false)
          } else {
            this.selectedContact = item
            this.promptCreateUserDialog = true
            // If user object is null do popup that says would you like to create user to add to campaign and proceed from there.
            this.createdContactCardId = item.contactCard.id
          }
            await this.getStakeholdersInCampaignAfterAdd()
            // delete 
          })
          .catch((err) => {
            console.log(err)
          })
        } else {
          // Remove user from campaign.
          this.deleteUserFromCampaign(item.user.id)
        }
      },

      deleteUserFromCampaign(userId) {
        CampaignController.deleteUser({
            campaignId: this.campaignId,
            userId: userId,
          })
            .then((res) => {
              this.$root.$emit( 'snackbarSuccess',res.data.message)
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
              // re-tick the checkbox
              item.addedToCampaign = true
            })
      },

      deleteTeamFromCampaign () {
        UserController.removeTeamFromCampaign(this.campaignId,this.selectedTeamForDelete.id)
          .then((res) => {
            this.$root.$emit('snackbarSuccess', 'Team removed from campaign.')
            this.getTeamsInCampaign()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          }).finally(() => {
            this.selectedTeamForDelete = null
            this.deleteTeamFromCampaignDialog = false
          })
      },

      async processContactsInStakeholder(contactsInStakeholders) {
    // See which of the users in this stakeholder are added to the campaign.
    await CampaignController.getAllUsers(this.campaignId).then((res) => {
        // Create a new array to avoid direct mutation
        const processedContacts = [...contactsInStakeholders.data];
        
        for (var i = 0; i < processedContacts.length; i++) {
            // match id of contact card with Id of users added to campaign
            for (var j = 0; j < res.data.length; j++) {
                if (processedContacts[i].user) {
                    if (processedContacts[i].user.id === res.data[j].id) {
                        console.log("it's a match!")
                        processedContacts[i].addedToCampaign = true
                    }
                }
            }
        }

        // Use Vue.set to ensure reactivity while maintaining the original structure
        this.$set(this, 'usersAssociatedWithCampaign', processedContacts);

        // Function that returns the contact object if a contact in usersAssociatedWithCampaign has a owner property that is not null, otherwise returns false
        const hasOwner = this.usersAssociatedWithCampaign.find(contact => contact.owner !== null)
        
        // Check if owner id is in usersAssociatedWithCampaign or a stakeholder hasOwner, if it is, then set the isOwner of the contact to true
        if (this.ownerId || hasOwner) {
            this.usersAssociatedWithCampaign.forEach(contact => {
                if(contact.owner === null) {
                    contact.isOwner = false
                }
                if (contact.contactCard.id === (this.ownerId ? this.ownerId : hasOwner.contactCard.id)) {
                    contact.isOwner = true
                }
            })
        }
    })
},

      getStakeholdersInCampaign () {
        CampaignController.getCampaign(this.campaignId).then((res) => {
          this.campaignStakeholdersFromApi = res.data.campaignStakeholders
          //console.log('Initial campaign stakeholder from api')
          //console.log(this.campaignStakeholdersFromApi)
          // Set original campaign stakeholders to the campaign stakeholders from api AS A NEW copy array, without references
          this.originalCampaignStakeholders = [...this.campaignStakeholdersFromApi]
          this.selectedAddedStakeholder = this.campaignStakeholdersFromApi.length !== 0 ? [this.campaignStakeholdersFromApi[0]] : null
          //console.log('Initial original campaign stakeholder from api')
          //console.log(this.originalCampaignStakeholders)
          this.removeAlreadyAddedStakeholders()
        })
      },

      getTeamsInCampaign () {
        UserController.getCampaignTeams(this.campaignId).then((res) => {
          this.campaignTeamsFromApi = res.data
          //this.selectedAddedTeam = this.campaignTeamsFromApi.length !== 0 ? [this.campaignTeamsFromApi[0]] : null
          //this.removeAlreadyAddedTeams()
        })
      },

      async getStakeholdersInCampaignAfterAdd() {
        // Append the selectedAddedstakeholder  to the originalCampaignStakeholders array
        //console.log('-----')
        //console.log(this.originalCampaignStakeholders)
        //console.log(this.selectedAddedStakeholder)
        this.originalCampaignStakeholders.push(this.selectedAddedStakeholder[0])
        this.selectedStakeholder = null
        this.removeAlreadyAddedStakeholders()
        this.$nextTick()
      },
      // FUnction that adds all stakeholder that are already in the campaign and appends the selected stakeholder and returns the array
      formatStakeholdersArray(){
        const allStakeholderArr = []
        // Push all the stakeholders that are already in the campaign
        for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
          allStakeholderArr.push({
            stakeholderId: this.campaignStakeholdersFromApi[i].stakeholder.id,
            position: i,
          })
        }
        return allStakeholderArr
      },

      async addStakeholderToHoldingList(incomingStakeholder) {
        let obj = {
          id: incomingStakeholder.id,
          campaignId: this.campaignId,
          stakeholder: incomingStakeholder,
          position: this.campaignStakeholdersFromApi.length + 1,
          key: incomingStakeholder.id
        }
        this.campaignStakeholdersFromApi.push(obj)
        // Set selectedActiveStakeholder to the newValue
        this.selectedAddedStakeholder = [obj]
        // Set the selectedTab to the last index (newly added stakeholder)
        this.selectedTab = this.campaignStakeholdersFromApi.length - 1
      },

      async addStakeholderToCampaign () {
        if(this.selectedStakeholder === null) {
          this.$root.$emit('snackbarError', 'Please select a stakeholder to add to the campaign.')
          return
        }

        await this.addStakeholderToHoldingList(this.selectedStakeholder)
        await this.setStakeholders('Stakeholder added to campaign.',false)
        
      },

      async setStakeholders(successMessage,multiAdd) {
        CampaignController.setStakeholders({
          campaignId: this.campaignId,
          campaignStakeholders: this.formatStakeholdersArray(),
        })
          .then(async (res) => {
            this.$root.$emit('snackbarSuccess', successMessage)
            if(!multiAdd)
              await this.getStakeholdersInCampaignAfterAdd()
            else
              await this.getStakeholdersInCampaign()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      async addTeamToCampaign () {
        if(this.selectedTeam === null) {
          this.$root.$emit('snackbarError', 'Please select a team to add to the campaign.')
          return
        }
        UserController.addTeamToCampaign({
          campaignId: this.campaignId,
          teamId: this.selectedTeam.id,
        })
          .then(async (res) => {
            this.$root.$emit('snackbarSuccess', 'Team added to campaign.')
            await this.getTeamsInCampaign()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
          .finally(async () => {
            await this.addUsersOfTeamToCampaign(this.selectedTeam.id)
            this.selectedTeam = null
          })
      },

      async addUsersOfTeamToCampaign(teamId) {
        let allTeamUsers = await this.getAllTeamUsers(teamId)
        console.log("All team users:", allTeamUsers)
        // add each user of team to campaign
        for(const user of allTeamUsers) {
          console.log("User to be added from team:", user)  
          await this.addUserToCampaign(user.id, 'User of team added to campaign.',true)
        }
        
        // for all the team users, get the distinct stakeholders
        let teamStakeholders = []
        await allTeamUsers.forEach(async user => {
          teamStakeholders.push(user.contactCard.stakeholder)
        })
        // unique the stakeholders
        teamStakeholders = uniqBy(teamStakeholders, 'id');

        await teamStakeholders.forEach(async stakeholder => {
          console.log("Stakeholder to be added:", stakeholder)
          // is the stakeholder already in the campaign?
          let obj = this.campaignStakeholdersFromApi.find(stakehold => stakehold.stakeholder.id === stakeholder.id)
          if(obj === undefined) 
            await this.addStakeholderToHoldingList(stakeholder) // add the stakeholder to the campaign
        })
        // set the stakeholders
        await this.setStakeholders('Stakeholders of all team members added to campaign.',true)

        // select the first stakeholder
        console.log("should now tick the users")
        this.selectedAddedStakeholder = this.campaignStakeholdersFromApi.length > 0 ? [this.campaignStakeholdersFromApi[0]] : null
        
      },

      async addUserToCampaign(userId, successMessage,bypassErrorMessage) {
        await CampaignController.setUsers({
          campaignId: this.campaignId,
          userId: userId,
        })
          .then(async (res) => {
            console.log(res.data.message)
            this.$root.$emit('snackbarSuccess', successMessage ?? res.data.message)
          })
          .catch((err) => {
            if(!bypassErrorMessage)
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      async getAllTeamUsers(teamId) {
        const res = await UserController.getTeamUsers(teamId)
        return res.data
      },

      createUserAcc () {
        UserController.createUserFromContactCard({
          contactCardId: this.createdContactCardId,
          roleId: this.selectedRole,
        })
          .then((res) => {
            this.addUserToCampaign(res.data.id, 'User account created successfully and user added to campaign.',false)
            this.createdContactCardId = null
            this.selectedRole = null
            this.selectedContact = null
            this.promptCreateUserDialog = false
            this.getAllStakeholders()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      getRoles () {
        StakeholderController.getPrimaryRoles().then((res) => {
          this.roles = res.data
        })
      },

      // delete stakeholder FROM CAMPAIGN
      async deleteStakeholder () {
        const index = this.campaignStakeholdersFromApi.findIndex(
          (stakeholder) => stakeholder.id === this.selectedStakeholderForDelete.id,
        )
        var copyOriginal = [...this.originalCampaignStakeholders]
        
        // Splice campaignstakeholderfromapi at index
        this.campaignStakeholdersFromApi.splice(index, 1)
        
        // Reorder the array
        for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
          this.campaignStakeholdersFromApi[i].position = i
        }
        
        // Set the stakeholders
        await CampaignController.setStakeholders({
          campaignId: this.campaignId,
          campaignStakeholders: this.formatStakeholdersArray(),
        })
          .then(async () => {
            this.$root.$emit('snackbarSuccess','Successfully deleted stakeholder')
            const obj = copyOriginal.find(stakeholder => 
              stakeholder.stakeholder.id === this.selectedStakeholderForDelete.stakeholder.id
            )
            this.stakeholders.push(obj.stakeholder)
            
            const originalIndex = this.originalCampaignStakeholders.findIndex(
              (stakeholder) => stakeholder.stakeholder.id === this.selectedStakeholderForDelete.stakeholder.id,
            )
            this.originalCampaignStakeholders.splice(originalIndex, 1)
            
            this.deleteStakeholderFromCampaignDialog = false
            this.selectedStakeholderForDelete = null
            
            // Update selectedTab to handle deletion
            if (this.campaignStakeholdersFromApi.length > 0) {
              // If we deleted the last tab, select the new last tab
              if (this.selectedTab >= this.campaignStakeholdersFromApi.length) {
                this.selectedTab = this.campaignStakeholdersFromApi.length - 1
              }
              // Update selectedAddedStakeholder to match the current tab
              this.selectedAddedStakeholder = [this.campaignStakeholdersFromApi[this.selectedTab]]
            } else {
              // If no stakeholders left
              this.selectedTab = 0
              this.selectedAddedStakeholder = null
            }
            
            this.selectedStakeholder = null
            this.usersAssociatedWithCampaign = []
            this.$nextTick()
          })
          .catch((err) => {
            console.log(err)
          })
      },

      removeAlreadyAddedStakeholders () {
        for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
          for (var j = 0; j < this.stakeholders.length; j++) {
            if (
              this.campaignStakeholdersFromApi[i].stakeholder.id ===
              this.stakeholders[j].id
            ) {
              this.stakeholders.splice(j, 1)
            }
          }
        }
      },

      clearSearch () {
        this.stakeholderSearchString = null
        this.getAllStakeholders()
      },

      searchForStakeholder () {
        StakeholderController.searchStakeholders(this.stakeholderSearchString)
          .then((res) => {
            this.stakeholders = res.data
            // Remove stakeholders as an option if they've already been added to the campaign
            this.removeAlreadyAddedStakeholders()
          })
      },

      getAllStakeholders () {
        StakeholderController.allStakeholders()
          .then((res) => {
            this.stakeholders = res.data
            //console.log('All stakeholders: ')
            //console.log(this.stakeholders)
            // Remove stakeholders as an option if they've already been added to the campaign
            this.removeAlreadyAddedStakeholders()
          })
      },

      // get all teams
      getAllTeams () {
        UserController.getTeams()
          .then((res) => {
            this.teams = res.data
          })
      },
    },
  }
</script>
